import React, { useCallback } from 'react'
import Logo from '../../images/logoBlack.svg'
import * as styles from './notFoundPage.module.less'
import { getCurrentYear } from '../Footer/helpers'
import { Link } from 'gatsby'
import { useAnalytics } from '../../context/ga'
import { BASIS_PHONE } from '../../constants'

const NotFoundPage = ({ links }) => {
  const onAnalytics = useAnalytics((clickText, page) => ({
    eventCategory: 'notFound-blog',
    clickText,
    page,
  }))

  const handleAnalytics = useCallback(
    (clickText, page) => () => onAnalytics(clickText, page),
    [onAnalytics]
  )

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div>
          <img
            src={Logo}
            className={styles.logo}
            loading="lazy"
            alt={'логотип'}
          />
          <h1 className={styles.title}>Такой страницы нет</h1>
          <p className={styles.description}>
            Но у нас есть много других хороших&nbsp;страниц.
          </p>
          <ul className={styles.pages}>
            {links.map((link, index) => {
              return (
                <li
                  key={index}
                  className={styles.link}
                  onClick={handleAnalytics(link.text, link.href)}
                >
                  {link.target ? (
                    <a
                      href={link.href}
                      className={styles.link}
                      target={link.target}
                    >
                      {link.text}
                    </a>
                  ) : (
                    <Link to={link.href} className={styles.link}>
                      {link.text}
                    </Link>
                  )}
                </li>
              )
            })}
          </ul>
        </div>
        <div className={styles.footer}>
          <a className={styles.phone} href={`tel:${BASIS_PHONE.phoneHref}`}>
            {BASIS_PHONE.phoneNumber}
          </a>
          <p className={styles.copyright}>© {getCurrentYear()} Ипотека.Центр</p>
        </div>
      </div>
    </div>
  )
}
export default NotFoundPage
