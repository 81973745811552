// extracted by mini-css-extract-plugin
export var container = "notFoundPage-module--container--67bdd";
export var copyright = "notFoundPage-module--copyright--2b0db";
export var description = "notFoundPage-module--description--ab8e9";
export var footer = "notFoundPage-module--footer--be6f3";
export var link = "notFoundPage-module--link--52133";
export var logo = "notFoundPage-module--logo--d2975";
export var pages = "notFoundPage-module--pages--ec4b6";
export var phone = "notFoundPage-module--phone--da0fb";
export var picture = "notFoundPage-module--picture--6a41d";
export var title = "notFoundPage-module--title--583b6";
export var wrapper = "notFoundPage-module--wrapper--d32dc";