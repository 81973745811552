import React from 'react'
import NotFoundPage from '../components/NotFoundPage'
import { notFoundMain } from '../components/NotFoundPage/entity'
import { AnalyticsProvider } from '../context/ga'

const NotFound = () => (
  <AnalyticsProvider>
    <NotFoundPage links={notFoundMain} />
  </AnalyticsProvider>
)

export default NotFound
