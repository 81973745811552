export const notFoundMain = [
  {
    text: 'Ипотечная платформа',
    href: '/',
  },
  {
    text: 'ИЦ.Журнал',
    href: '/blog',
  },
  {
    text: 'Ипотечный университет',
    href: 'https://university.ipoteka.center/',
    target: '__blank',
  },
  {
    text: 'Карьера',
    href: 'https://career.ipoteka.center/',
    target: '__blank',
  },
  {
    text: 'Мероприятия',
    href: 'https://events.ipoteka.center/',
    target: '__blank',
  },
]

export const notFoundBlog = [
  {
    text: 'ИЦ.Журнал',
    href: '/blog',
  },
  {
    text: 'Статьи',
    href: '/blog/category/articles',
  },
  {
    text: 'Истории',
    href: '/blog/category/stories',
  },
  {
    text: 'Лайфхаки',
    href: '/blog/category/lifehacks',
  },
  {
    text: 'Балансборд',
    href: '/blog/category/balansbord',
  },
  {
    text: 'Цифровая платформа',
    href: '/',
  },
]
